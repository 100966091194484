import { useMemo } from 'react'
import { endOfDay, startOfDay } from 'date-fns'

import { DatePicker } from '../DatePicker'
import { Option, Select } from '../Select'

import { getDateRangeOptions } from 'lib/utils/getDateRangeOptions'

type Props = {
    className?: string
    label?: string
    value: string
    start: Date | null
    end: Date | null
    setValue: (val: string) => void
    setStart: (val: Date | null) => void
    setEnd: (val: Date | null) => void
}

export const SelectDateRangeForm = ({
    className,
    label,
    value,
    start,
    end,
    setValue,
    setStart,
    setEnd,
}: Props) => {
    // TODO: add default range/start/end
    const { options, optionsMap } = useMemo(() => getDateRangeOptions(), [])

    return (
        <div className={className}>
            <Select
                label={label}
                value={value}
                onChange={(v) => {
                    const selectedDateRange = optionsMap[v]
                    setValue(selectedDateRange.value)
                    setStart(selectedDateRange.start)
                    setEnd(selectedDateRange.end)
                }}
            >
                {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
            {value === 'custom' && (
                <div className="row stacked-sm padding-top-sm">
                    <DatePicker
                        className="col-6"
                        placeholder="Desde"
                        value={start}
                        onChange={(v) => setStart(v ? startOfDay(v) : null)}
                    />
                    <DatePicker
                        className="col-6"
                        placeholder="Hasta"
                        value={end}
                        onChange={(v) => setEnd(v ? endOfDay(v) : null)}
                    />
                </div>
            )}
        </div>
    )
}
